<template>
  <div>
    <div>
      <div style="color:black; font-size:12px;  font-weight:bold; white-space: normal; overflow: visible;">
        {{ product.name }}
      </div>
      <barcode
        :value="product.reference"
        :width="1.6"
        :height="40"
      />
    </div>

    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="outline-secondary"
      class="mb-75 btn-print"
      block
      @click="print"
    >
      Print
    </b-button>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'

import {
  BButton, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  name: 'ShowWorkCertificate',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    barcode: VueBarcode,
    BButton,
  },
  data() {
    return {
      workCertificate: null,
      product: {},
    }
  },
  created() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      const { data } = await axiosIns.get(`/products/${this.$route.params.id}`)
      this.product = { ...data }
    },
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

  },
  setup() {
    const print = () => {
      window.print()
    }

    return {
      print,

    }
  },
}
</script>

<style lang="scss">
@media print {
  @page {
    size: 4cm 2cm; /* Set the size to 4cm by 2cm */
    margin: 0rem !important;
    padding: 0rem !important;
  }

   .centered-text {
    color: black;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    max-width: 4cm;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
  }

  body {
    background-color: transparent !important;
    margin: 0rem !important;
    padding: 0rem !important;

  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin: 0rem !important;
    margin-left: 10px !important;
    padding: 0rem !important;
  }
  .btn-print{
    display: none;
  }
}

</style>
